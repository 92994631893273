import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCartBuyOrder } from '../../../redux/cart/selector'
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { NavLink } from 'react-router-dom'

const CartItem = ({ image, name, link, priceCNY }) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">
      {image ? (
        <img
          src={`${image.indexOf('http') === -1 ? process.env.REACT_APP_API : ''}${image}`}
          alt={name}
          className="img-thumbnail border w-25"
        />
      ) : (
        <img src="/assets/img/default-thumbnail.jpg" alt={name} className="img-thumbnail border w-25" />
      )}

      <div className="pl-3 pr-2">
        <a href={link} rel="noopener noreferrer" target="_blank" className="font-weight-medium mb-1">
          {name}
        </a>
        <p className="text-muted mb-0 text-small">&yen; {priceCNY}</p>
      </div>
    </div>
  )
}

const TopnavCart = ({ cartBuyOrder }) => {
  const cartItems = cartBuyOrder.items

  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle className="header-icon notificationButton" color="empty">
          <i className="simple-icon-basket-loaded" />
          <span className="count">{cartItems.length}</span>
        </DropdownToggle>
        <DropdownMenu className="position-absolute mt-3 scroll" right id="notificationDropdown">
          <div style={{ height: '280px' }}>
            {cartItems.length > 0 ? (
              <PerfectScrollbar
                options={{
                  suppressScrollX: true,
                  suppressScrollY: false,
                  wheelPropagation: false,
                }}
              >
                {cartBuyOrder.items.map((cartItem, index) => {
                  return <CartItem key={index} {...cartItem} />
                })}
              </PerfectScrollbar>
            ) : (
              <span className="d-block text-center text-muted">Không có sản phẩm</span>
            )}
          </div>
          {cartItems.length > 0 && (
            <Button color="secondary" to="/create-order/new-order" tag={NavLink} className="default mt-3" block>
              Đến giỏ hàng
            </Button>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  cartBuyOrder: selectCartBuyOrder,
})

export default connect(mapStateToProps, null)(TopnavCart)
