import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import { CHAT_ADD_MESSAGE_TO_CONVERSATION, CHAT_GET_CONVERSATION, CHAT_GET_HISTORY, GET_HAS_CHAT } from '../actions'

import {
  addMessageToConversationError,
  disableIsNewMessage,
  enableIsNewMessage,
  getChatHistoryError,
  getChatHistorySuccess,
  getConversationError,
  getConversationSuccess,
} from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'
import moment from 'moment'

/**
 * Get Chat History
 */
export function* watchGetChatHistory() {
  yield takeEvery(CHAT_GET_HISTORY, loadChatHistory)
}

const loadChatHistoryAsync = async () => {
  return apiClient.get(`/chat`)
}

function* loadChatHistory() {
  try {
    const response = yield call(loadChatHistoryAsync)
    if (!response.data.status) {
      yield put(getChatHistoryError('Lỗi Khi Get Lịch Sử Chat'))
      yield notification.danger('Lỗi Khi Get Lịch Sử Chat')
    } else {
      let haveChatToday = false
      let todayFormatted = moment().format('DD/MM/YYYY')
      let chatData = response.data.data

      for (let i = 0; i < chatData.length; i++) {
        if (moment(chatData[i].createdAt).format('DD/MM/YYYY') === todayFormatted) {
          haveChatToday = true
          break
        }
      }

      if (!haveChatToday) {
        chatData = [
          {
            _id: 'new',
            messages: [],
            createdAt: moment().format(),
          },
          ...chatData,
        ]
      } else {
        const responseChat = yield call(loadChatConversationAsync, chatData[0]._id)

        if (!responseChat.data.status) {
          yield notification.danger('Lỗi load hội thoại')
        } else {
          chatData[0].messages = responseChat.data.data.messages
        }
      }

      let selectedChatId = chatData[0]._id
      yield put(getChatHistorySuccess(chatData, selectedChatId))
    }
  } catch (error) {
    yield notification.danger('Lỗi Khi Get Lịch Sử Chat')
    yield put(getChatHistoryError(error.message))
  }
}

/**
 * Get Chat Conversation By ChatId
 */
export function* watchGetChatConversation() {
  yield takeEvery(CHAT_GET_CONVERSATION, loadChatConversation)
}

const loadChatConversationAsync = async (chatId) => {
  return apiClient.get(`/chat/${chatId}`)
}

function* loadChatConversation({ payload }) {
  const { chatId } = payload
  try {
    const response = yield call(loadChatConversationAsync, chatId)
    if (!response.data.status) {
      yield put(getConversationError('Lỗi load hội thoại'))
      yield notification.danger('Lỗi load hội thoại')
    } else {
      yield put(getConversationSuccess(response.data.data, chatId))
    }
  } catch (error) {
    yield notification.danger(error.message)
    yield put(getConversationError(error.message))
  }
}

/**
 * Add Message To Conversation
 */
export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation)
}

const addMessageToConversationAsync = async (msg) => {
  return apiClient.post(`/chat/`, { msg })
}

function* addMessageToConversation({ payload }) {
  const { msg } = payload
  try {
    const response = yield call(addMessageToConversationAsync, msg)
    if (!response.data.status) {
      yield put(addMessageToConversationError('Lỗi thêm tin nhắn'))
      yield notification.danger('Lỗi thêm tin nhắn')
    } else {
      // yield put(addMessageToConversationSuccess(response.data.data));
    }
  } catch (error) {
    yield notification.danger(error.message)
    // yield put(addMessageToConversationError(error.message));
  }
}

/**
 * Get Has Chat Notification
 */
export function* watchGetHasChat() {
  yield takeEvery(GET_HAS_CHAT, getHasChat)
}

const getHasChatAsync = async () => {
  return apiClient.get(`/customer/chat`)
}

function* getHasChat() {
  try {
    const response = yield call(getHasChatAsync)
    if (!response.data.status) {
      yield notification.danger('Lỗi khi kiểm tra danh sách chat')
    } else {
      const chatList = response.data.data
      // console.log(chatList);
      if (chatList.length > 0) {
        yield put(enableIsNewMessage())
      } else {
        yield put(disableIsNewMessage())
      }
    }
  } catch (error) {
    yield notification.danger(error.message)
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetChatHistory),
    fork(watchGetChatConversation),
    fork(watchAddMessageToConversation),
    fork(watchGetHasChat),
  ])
}
