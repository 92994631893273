import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import NotificationContainer from '../layouts/main-layout/notifications/notification-container'
import { AuthRoute, NonAuthRoute } from './app.route'

export class App extends Component {
  constructor(props) {
    super(props)
    document.body.classList.add('ltr')
  }

  render() {
    return (
      <div className="h-100">
        <NotificationContainer />
        <Suspense fallback={<div className="loading" />}>
          <Router>
            <Switch>
              <Redirect exact from="/" to="/dashboards" />
              <AuthRoute
                path="/dashboards"
                component={React.lazy(() => import(/* webpackChunkName: "dashboards" */ '../pages/dashboard'))}
              />
              <AuthRoute
                path="/search-product"
                component={React.lazy(() => import(/* webpackChunkName: "dashboards" */ '../pages/dashboard'))}
              />
              <AuthRoute
                path="/buy-order-new"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "create-buy-order" */ '../pages/buy-order-new')
                )}
              />
              <AuthRoute
                path="/buy-order/:id"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "buy-order-detail" */ '../pages/buy-order-detail')
                )}
              />
              <AuthRoute
                path="/buy-order-list"
                component={React.lazy(() => import(/* webpackChunkName: "manage-order" */ '../pages/buy-order-list'))}
              />
              <AuthRoute
                path="/buy-ship-new"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "create-ship-order" */ '../pages/ship-order-new')
                )}
              />
              <AuthRoute
                path="/ship-order/:id"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "ship-order-detail" */ '../pages/ship-order-detail')
                )}
              />
              <AuthRoute
                path="/ship-order-list"
                component={React.lazy(() => import(/* webpackChunkName: "manage-order" */ '../pages/ship-order-list'))}
              />
              <AuthRoute
                path="/complaint/:id"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "complaint-detail" */ '../pages/complaint-detail/complaint-detail')
                )}
              />
              <AuthRoute
                path="/e-wallet"
                component={React.lazy(() => import(/* webpackChunkName: "e-wallet" */ '../pages/e-wallet'))}
              />
              <AuthRoute
                path="/complaint"
                component={React.lazy(() =>
                  import(/* webpackChunkName: "complaint" */ '../pages/complaint/all-complaint')
                )}
              />
              <AuthRoute
                path="/chat"
                component={React.lazy(() => import(/* webpackChunkName: "chat" */ '../pages/chat'))}
              />
              <AuthRoute
                path="/profile"
                component={React.lazy(() => import(/* webpackChunkName: "profile" */ '../pages/profile'))}
              />
              <NonAuthRoute
                path="/login"
                component={React.lazy(() => import(/* webpackChunkName: "login" */ '../pages/login'))}
              />
              <NonAuthRoute
                path="/register"
                component={React.lazy(() => import(/* webpackChunkName: "register" */ '../pages/register'))}
              />
              <NonAuthRoute
                path="/error"
                component={React.lazy(() => import(/* webpackChunkName: "error" */ '../pages/error'))}
              />
              <Redirect to="/error" />
            </Switch>
          </Router>
        </Suspense>
      </div>
    )
  }
}
