import React, { Component } from 'react'
import './top-nav.scss'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  clickOnMobileMenu,
  disableIsNewMessage,
  enableIsNewMessage,
  getProfileStart,
  getUserFeeStart,
  logoutUser,
  setContainerClassnames,
} from '../../../redux/actions'
import { MenuIcon, MobileMenuIcon } from '../../../components/svg'
import TopnavCart from './top-nav.cart'
import { isEmpty } from 'lodash'
import { formatCurrency } from '../../../helpers/utils'
import { createStructuredSelector } from 'reselect'
import { selectAuthUserFeeData, selectAuthUserProfileMapped } from '../../../redux/auth/selector'
import { selectIsNewMessage } from '../../../redux/chat/selector'
import {
  selectContainerClassnames,
  selectMenuClickCount,
  selectSelectedMenuHasSubItems,
} from '../../../redux/menu/selector'
import io from 'socket.io-client'

class TopNav extends Component {
  handleLogout = () => {
    this.props.logoutUser(this.props.history)
  }

  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault()

    setTimeout(() => {
      var event = document.createEvent('HTMLEvents')
      event.initEvent('resize', false, false)
      window.dispatchEvent(event)
    }, 350)
    this.props.setContainerClassnames(++menuClickCount, containerClassnames, this.props.selectedMenuHasSubItems)
  }

  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault()
    this.props.clickOnMobileMenu(containerClassnames)
  }

  componentDidMount() {
    this.props.getProfileStart()
    this.props.getUserFeeStart()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let socket = io(`${process.env.REACT_APP_SOCKET}`)
    const {
      match: { url },
      currentSender,
      enableIsNewMessage,
      disableIsNewMessage,
    } = this.props

    if (!isEmpty(currentSender)) {
      socket.on(`chat new ${currentSender._id}`, (data) => {
        if (!isEmpty(data) && url !== '/chat') {
          enableIsNewMessage()
        } else {
          disableIsNewMessage()
        }
      })
    }
  }

  checkNewChatMessage = (isNewMessage) => {
    return <span className="count">{isNewMessage ? '*' : 0}</span>
  }

  render() {
    const { containerClassnames, menuClickCount, currentSender, userFee, isNewMessage } = this.props

    return (
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <NavLink
            to="#"
            location={{}}
            className="menu-button d-none d-md-block"
            onClick={(e) => this.menuButtonClick(e, menuClickCount, containerClassnames)}
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            location={{}}
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={(e) => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>

          <div className="d-inline-block mr-3">
            <div className="text-left">
              <span className="d-block text-white">Hotline</span>
              <h4 className="d-block top_nav_text_orange">
                <b>0566 913 913</b>
              </h4>
            </div>
          </div>

          <div className="d-inline-block">
            <div className="text-left">
              <span className="d-block text-white">Tỉ giá</span>
              <h4 className="d-block top_nav_text_orange">
                {!isEmpty(userFee) ? <b>{formatCurrency(userFee.rate)}</b> : <b>0</b>}
              </h4>
            </div>
          </div>
        </div>

        <NavLink className="navbar-logo" to="/">
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </NavLink>

        <div className="navbar-right">
          <div className="header-icons d-inline-block align-middle">
            <div className="position-relative d-inline-block">
              <NavLink to="/chat" className="header-icon notificationButton btn btn-empty">
                <i className="simple-icon-bubble" />
                {this.checkNewChatMessage(isNewMessage)}
              </NavLink>
            </div>
          </div>
          <div className="header-icons d-inline-block align-middle">
            <TopnavCart />
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <span className="name mr-1 text-white">Xin Chào!</span>
                {!isEmpty(currentSender) ? (
                  <span>{currentSender.name}</span>
                ) : (
                  <span>
                    <img alt="Profile" src="/assets/img/default-avatar.png" />
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                <DropdownItem>
                  <NavLink to="/profile">Tài khoản</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.handleLogout()}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  currentSender: selectAuthUserProfileMapped,
  userFee: selectAuthUserFeeData,
  containerClassnames: selectContainerClassnames,
  menuClickCount: selectMenuClickCount,
  selectedMenuHasSubItems: selectSelectedMenuHasSubItems,
  isNewMessage: selectIsNewMessage,
})

const mapDispatchToProps = (dispatch) => ({
  enableIsNewMessage: () => dispatch(enableIsNewMessage()),
  disableIsNewMessage: () => dispatch(disableIsNewMessage()),
  setContainerClassnames: (clickIndex, strCurrentClasses, selectedMenuHasSubItems) =>
    dispatch(setContainerClassnames(clickIndex, strCurrentClasses, selectedMenuHasSubItems)),
  clickOnMobileMenu: (strCurrentClasses) => dispatch(clickOnMobileMenu(strCurrentClasses)),
  logoutUser: (history) => dispatch(logoutUser(history)),
  getProfileStart: () => dispatch(getProfileStart()),
  getUserFeeStart: () => dispatch(getUserFeeStart()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav))
